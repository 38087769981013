


export type ProtectionStatus = 'None' | 'Deserving' | 'Heritage';

export const ProtectionStatus = {
    None: 'None' as ProtectionStatus,
    Deserving: 'Deserving' as ProtectionStatus,
    Heritage: 'Heritage' as ProtectionStatus
};

