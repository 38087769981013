


export type AuthorType = 'RuleEngine' | 'Abacus' | 'PersonalClient' | 'TerminalClient' | 'WebClient' | 'RuleProcessor' | 'TransmitService';

export const AuthorType = {
    RuleEngine: 'RuleEngine' as AuthorType,
    Abacus: 'Abacus' as AuthorType,
    PersonalClient: 'PersonalClient' as AuthorType,
    TerminalClient: 'TerminalClient' as AuthorType,
    WebClient: 'WebClient' as AuthorType,
    RuleProcessor: 'RuleProcessor' as AuthorType,
    TransmitService: 'TransmitService' as AuthorType
};

