/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PurchaseOrderDetailDto } from '../model/purchaseOrderDetailDto';
import { PurchaseOrderItemDto } from '../model/purchaseOrderItemDto';
import { PurchaseOrderOverviewDtoSearchResponse } from '../model/purchaseOrderOverviewDtoSearchResponse';
import { PurchaseOrderSearchRequestDto } from '../model/purchaseOrderSearchRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PurchaseOrderService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(body?: PurchaseOrderDetailDto, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderDetailDto>;
    public create(body?: PurchaseOrderDetailDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderDetailDto>>;
    public create(body?: PurchaseOrderDetailDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderDetailDto>>;
    public create(body?: PurchaseOrderDetailDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/purchase-orders`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param purchaseOrderId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderItemDto>;
    public createItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderItemDto>>;
    public createItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderItemDto>>;
    public createItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling createItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(purchaseOrderId))}/item`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePurchaseOrder(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public deletePurchaseOrder(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public deletePurchaseOrder(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public deletePurchaseOrder(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletePurchaseOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param purchaseOrderId 
     * @param itemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePurchaseOrderItem(purchaseOrderId: string, itemId: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public deletePurchaseOrderItem(purchaseOrderId: string, itemId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public deletePurchaseOrderItem(purchaseOrderId: string, itemId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public deletePurchaseOrderItem(purchaseOrderId: string, itemId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling deletePurchaseOrderItem.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling deletePurchaseOrderItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(purchaseOrderId))}/item/${encodeURIComponent(String(itemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetail(id: string, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderDetailDto>;
    public getDetail(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderDetailDto>>;
    public getDetail(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderDetailDto>>;
    public getDetail(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public save(body?: PurchaseOrderDetailDto, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderDetailDto>;
    public save(body?: PurchaseOrderDetailDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderDetailDto>>;
    public save(body?: PurchaseOrderDetailDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderDetailDto>>;
    public save(body?: PurchaseOrderDetailDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/purchase-orders`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param purchaseOrderId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderItemDto>;
    public saveItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderItemDto>>;
    public saveItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderItemDto>>;
    public saveItem(purchaseOrderId: string, body?: PurchaseOrderItemDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling saveItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(purchaseOrderId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: PurchaseOrderSearchRequestDto, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderOverviewDtoSearchResponse>;
    public search(body?: PurchaseOrderSearchRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderOverviewDtoSearchResponse>>;
    public search(body?: PurchaseOrderSearchRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderOverviewDtoSearchResponse>>;
    public search(body?: PurchaseOrderSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/purchase-orders/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendToAbacus(id: string, observe?: 'body', reportProgress?: boolean): Promise<PurchaseOrderDetailDto>;
    public sendToAbacus(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PurchaseOrderDetailDto>>;
    public sendToAbacus(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PurchaseOrderDetailDto>>;
    public sendToAbacus(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sendToAbacus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/purchase-orders/${encodeURIComponent(String(id))}/send`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
