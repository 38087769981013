


export type TimeEntryTypeDossierOptions = 'Default' | 'ShowInformation' | 'RequiresUpload';

export const TimeEntryTypeDossierOptions = {
    Default: 'Default' as TimeEntryTypeDossierOptions,
    ShowInformation: 'ShowInformation' as TimeEntryTypeDossierOptions,
    RequiresUpload: 'RequiresUpload' as TimeEntryTypeDossierOptions
};

