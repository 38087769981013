


export type FormFieldFileType = 'Image' | 'Pdf';

export const FormFieldFileType = {
    Image: 'Image' as FormFieldFileType,
    Pdf: 'Pdf' as FormFieldFileType
};

