/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DossierDto } from '../model/dossierDto';
import { SignResponseDto } from '../model/signResponseDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DossierDocumentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param tenant 
     * @param projectExternalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForProject(tenant: number, projectExternalId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<DossierDto>>;
    public getForProject(tenant: number, projectExternalId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<DossierDto>>>;
    public getForProject(tenant: number, projectExternalId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<DossierDto>>>;
    public getForProject(tenant: number, projectExternalId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getForProject.');
        }
        if (projectExternalId === null || projectExternalId === undefined) {
            throw new Error('Required parameter projectExternalId was null or undefined when calling getForProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/document-dossier/project/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(projectExternalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tenant 
     * @param workspaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForWorkspace(tenant: number, workspaceId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<DossierDto>>;
    public getForWorkspace(tenant: number, workspaceId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<DossierDto>>>;
    public getForWorkspace(tenant: number, workspaceId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<DossierDto>>>;
    public getForWorkspace(tenant: number, workspaceId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getForWorkspace.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling getForWorkspace.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/document-dossier/workspace/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(workspaceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tenant 
     * @param path 
     * @param validForSeconds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sign(tenant: number, path?: string, validForSeconds?: number, observe?: 'body', reportProgress?: boolean): Promise<SignResponseDto>;
    public sign(tenant: number, path?: string, validForSeconds?: number, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<SignResponseDto>>;
    public sign(tenant: number, path?: string, validForSeconds?: number, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<SignResponseDto>>;
    public sign(tenant: number, path?: string, validForSeconds?: number, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling sign.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (path !== undefined && path !== null) {
            queryParameters = queryParameters.set('path', <any>path);
        }
        if (validForSeconds !== undefined && validForSeconds !== null) {
            queryParameters = queryParameters.set('validForSeconds', <any>validForSeconds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/document-dossier/sign/${encodeURIComponent(String(tenant))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
