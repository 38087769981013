


export type ProjectBookingType = 'Timebooking' | 'Material' | 'Anlage' | 'ClientContribution';

export const ProjectBookingType = {
    Timebooking: 'Timebooking' as ProjectBookingType,
    Material: 'Material' as ProjectBookingType,
    Anlage: 'Anlage' as ProjectBookingType,
    ClientContribution: 'ClientContribution' as ProjectBookingType
};

