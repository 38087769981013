


export type SalesOrderType = 'Normal' | 'Return';

export const SalesOrderType = {
    Normal: 'Normal' as SalesOrderType,
    Return: 'Return' as SalesOrderType
};

