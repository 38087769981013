


export type ProductGroup = 'SalesProduct' | 'Material';

export const ProductGroup = {
    SalesProduct: 'SalesProduct' as ProductGroup,
    Material: 'Material' as ProductGroup
};

