


export type TimebookingState = 'Transmitted' | 'Pending' | 'Failed';

export const TimebookingState = {
    Transmitted: 'Transmitted' as TimebookingState,
    Pending: 'Pending' as TimebookingState,
    Failed: 'Failed' as TimebookingState
};

