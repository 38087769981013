


export type TimebookingDossierStateFlags = 'UploadedToAbacus' | 'AbacusUploadError';

export const TimebookingDossierStateFlags = {
    UploadedToAbacus: 'UploadedToAbacus' as TimebookingDossierStateFlags,
    AbacusUploadError: 'AbacusUploadError' as TimebookingDossierStateFlags
};

