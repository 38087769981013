


export type ProductType = 'Undefined' | 'Product' | 'Diverse';

export const ProductType = {
    Undefined: 'Undefined' as ProductType,
    Product: 'Product' as ProductType,
    Diverse: 'Diverse' as ProductType
};

