


export type ProjectType = 'None' | 'ProjectExecution' | 'ProjectInvoice' | 'ProjectOffer' | 'ProjectObject';

export const ProjectType = {
    None: 'None' as ProjectType,
    ProjectExecution: 'ProjectExecution' as ProjectType,
    ProjectInvoice: 'ProjectInvoice' as ProjectType,
    ProjectOffer: 'ProjectOffer' as ProjectType,
    ProjectObject: 'ProjectObject' as ProjectType
};

