


export type EffortType = 'Work' | 'Absence' | 'Expenses' | 'UnproductiveWork' | 'NonTimebooking';

export const EffortType = {
    Work: 'Work' as EffortType,
    Absence: 'Absence' as EffortType,
    Expenses: 'Expenses' as EffortType,
    UnproductiveWork: 'UnproductiveWork' as EffortType,
    NonTimebooking: 'NonTimebooking' as EffortType
};

