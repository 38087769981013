


export type SortDirection = 'Ascending' | 'Descending';

export const SortDirection = {
    Ascending: 'Ascending' as SortDirection,
    Descending: 'Descending' as SortDirection
};

