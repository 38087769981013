/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FormDefinitionSearchDto } from '../model/formDefinitionSearchDto';
import { FormDefinitionSearchDtoSearchResponse } from '../model/formDefinitionSearchDtoSearchResponse';
import { FormDefinitionSearchRequestDto } from '../model/formDefinitionSearchRequestDto';
import { FormVersionDefinitionDto } from '../model/formVersionDefinitionDto';
import { UploadPolicy } from '../model/uploadPolicy';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FormDefinitionService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneForm(body?: FormVersionDefinitionDto, observe?: 'body', reportProgress?: boolean): Promise<FormVersionDefinitionDto>;
    public cloneForm(body?: FormVersionDefinitionDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormVersionDefinitionDto>>;
    public cloneForm(body?: FormVersionDefinitionDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormVersionDefinitionDto>>;
    public cloneForm(body?: FormVersionDefinitionDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/form-definition/clone`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteForm(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public deleteForm(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public deleteForm(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public deleteForm(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteForm.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/form-definition/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling favorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/form-definition/${encodeURIComponent(String(id))}/favorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(id: string, observe?: 'body', reportProgress?: boolean): Promise<FormVersionDefinitionDto>;
    public get(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormVersionDefinitionDto>>;
    public get(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormVersionDefinitionDto>>;
    public get(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-definition/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getDownloadRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getDownloadRequest.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling getDownloadRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-definition/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(filename))}/download/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSearchDto(id: string, observe?: 'body', reportProgress?: boolean): Promise<FormDefinitionSearchDto>;
    public getSearchDto(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormDefinitionSearchDto>>;
    public getSearchDto(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormDefinitionSearchDto>>;
    public getSearchDto(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSearchDto.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-definition/search/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param filename 
     * @param filetype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadFileRequest(formId: string, fieldId: string, filename: string, filetype: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadFileRequest(formId: string, fieldId: string, filename: string, filetype: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, filename: string, filetype: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, filename: string, filetype: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadFileRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadFileRequest.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling getUploadFileRequest.');
        }
        if (filetype === null || filetype === undefined) {
            throw new Error('Required parameter filetype was null or undefined when calling getUploadFileRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/form-definition/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(filename))}/${encodeURIComponent(String(filetype))}/upload/request`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveForm(body?: FormVersionDefinitionDto, observe?: 'body', reportProgress?: boolean): Promise<FormVersionDefinitionDto>;
    public saveForm(body?: FormVersionDefinitionDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormVersionDefinitionDto>>;
    public saveForm(body?: FormVersionDefinitionDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormVersionDefinitionDto>>;
    public saveForm(body?: FormVersionDefinitionDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/form-definition`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchForms(body?: FormDefinitionSearchRequestDto, id?: string, observe?: 'body', reportProgress?: boolean): Promise<FormDefinitionSearchDtoSearchResponse>;
    public searchForms(body?: FormDefinitionSearchRequestDto, id?: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormDefinitionSearchDtoSearchResponse>>;
    public searchForms(body?: FormDefinitionSearchRequestDto, id?: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormDefinitionSearchDtoSearchResponse>>;
    public searchForms(body?: FormDefinitionSearchRequestDto, id?: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/form-definition/search`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavorite(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavorite(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavorite(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavorite(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unfavorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/form-definition/${encodeURIComponent(String(id))}/unfavorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
