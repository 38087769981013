


export type ProductSearchMode = 'Sale' | 'Purchase' | 'All';

export const ProductSearchMode = {
    Sale: 'Sale' as ProductSearchMode,
    Purchase: 'Purchase' as ProductSearchMode,
    All: 'All' as ProductSearchMode
};

