import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Event, EventHint } from '@sentry/angular';
import * as moment from 'moment';

import { environment } from './environments/environment';
import 'moment/locale/de-ch';
import { extendConfiguration } from '../../common/src/lib/configuration/config';

extendConfiguration(environment).then(async () => {
  if (environment.production) {
    enableProdMode();
  }

  if (environment.sentry) {
    Sentry.init({
      dsn: environment.sentry,
      environment: environment.kronosApiUrl.split('.').splice(1, 3).join('.'),
      release:
        environment.version === '0.0.0' ? environment.commitHash : environment.version,

      beforeSend(
        event: Event,
        hint?: EventHint,
      ): PromiseLike<Event | null> | Event | null {
        const error = hint.originalException as any;
        const ignoredStatuses = [401, 403];

        if (error && error.status && ignoredStatuses.includes(error.status)) {
          return null;
        }
        return event;
      },
    });
  }
  const module = await import('./app/app.module');
  platformBrowserDynamic()
    .bootstrapModule(module.AppModule)
    .catch((err) => console.error(err));
});

moment.updateLocale('de', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const chunkFailedMessageRegex = /Loading chunk \d+ failed/;
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistration().then((reg) => {
    if (reg?.active) {
      reg.active.onerror = (err) => {
        if (err?.message && chunkFailedMessageRegex.test(err.message)) {
          console.log('detected chunk load failure');
          window.location.reload();
        }
      };
    }
  });
}
