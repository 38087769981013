


export type ZulipChannelLabel = 'None' | 'ProjectInvoice' | 'ProjectExecution' | 'ProjectObject';

export const ZulipChannelLabel = {
    None: 'None' as ZulipChannelLabel,
    ProjectInvoice: 'ProjectInvoice' as ZulipChannelLabel,
    ProjectExecution: 'ProjectExecution' as ZulipChannelLabel,
    ProjectObject: 'ProjectObject' as ZulipChannelLabel
};

