


export type PostLinkRels = 'Self' | 'Newer' | 'Older' | 'Newest';

export const PostLinkRels = {
    Self: 'Self' as PostLinkRels,
    Newer: 'Newer' as PostLinkRels,
    Older: 'Older' as PostLinkRels,
    Newest: 'Newest' as PostLinkRels
};

