


export type SearchRequestTodoType = 'Mine' | 'Created' | 'NotAssigned' | 'MineDone' | 'Done' | 'Open' | 'ConstructionSite' | 'All';

export const SearchRequestTodoType = {
    Mine: 'Mine' as SearchRequestTodoType,
    Created: 'Created' as SearchRequestTodoType,
    NotAssigned: 'NotAssigned' as SearchRequestTodoType,
    MineDone: 'MineDone' as SearchRequestTodoType,
    Done: 'Done' as SearchRequestTodoType,
    Open: 'Open' as SearchRequestTodoType,
    ConstructionSite: 'ConstructionSite' as SearchRequestTodoType,
    All: 'All' as SearchRequestTodoType
};

