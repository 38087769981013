


export type ExpenseOptions = 'Lunch' | 'FlatRate' | 'LunchExternal';

export const ExpenseOptions = {
    Lunch: 'Lunch' as ExpenseOptions,
    FlatRate: 'FlatRate' as ExpenseOptions,
    LunchExternal: 'LunchExternal' as ExpenseOptions
};

