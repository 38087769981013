


export type FormUsage = 'Qr' | 'Email';

export const FormUsage = {
    Qr: 'Qr' as FormUsage,
    Email: 'Email' as FormUsage
};

