


export type CustomerReportType = 'DailyReport' | 'AdditionalLabourReport';

export const CustomerReportType = {
    DailyReport: 'DailyReport' as CustomerReportType,
    AdditionalLabourReport: 'AdditionalLabourReport' as CustomerReportType
};

