


export type AppArea = 'SalesOrderTransmitter' | 'TimeBookingTransmitter' | 'PurchaseOrderTransmitter' | 'DossierTransmitter' | 'InventoryTransmitter' | 'FastLabels';

export const AppArea = {
    SalesOrderTransmitter: 'SalesOrderTransmitter' as AppArea,
    TimeBookingTransmitter: 'TimeBookingTransmitter' as AppArea,
    PurchaseOrderTransmitter: 'PurchaseOrderTransmitter' as AppArea,
    DossierTransmitter: 'DossierTransmitter' as AppArea,
    InventoryTransmitter: 'InventoryTransmitter' as AppArea,
    FastLabels: 'FastLabels' as AppArea
};

