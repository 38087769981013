


export type TimeEntryTypeRegieOptions = 'None' | 'ShowRegieOption';

export const TimeEntryTypeRegieOptions = {
    None: 'None' as TimeEntryTypeRegieOptions,
    ShowRegieOption: 'ShowRegieOption' as TimeEntryTypeRegieOptions
};

