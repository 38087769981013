


export type ColorType = 'Green' | 'Orange' | 'Blue' | 'Yellow' | 'Transparent' | 'Gray' | 'Purple';

export const ColorType = {
    Green: 'Green' as ColorType,
    Orange: 'Orange' as ColorType,
    Blue: 'Blue' as ColorType,
    Yellow: 'Yellow' as ColorType,
    Transparent: 'Transparent' as ColorType,
    Gray: 'Gray' as ColorType,
    Purple: 'Purple' as ColorType
};

