


export type AppState = 'Active' | 'InActive';

export const AppState = {
    Active: 'Active' as AppState,
    InActive: 'InActive' as AppState
};

