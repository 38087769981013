


export type TodoVisibility = 'Public' | 'Private';

export const TodoVisibility = {
    Public: 'Public' as TodoVisibility,
    Private: 'Private' as TodoVisibility
};

