


export type TimeEntryTypeAnlageAndMaterialOptions = 'None' | 'AnlageInBookingMandatory' | 'MaterialOptional' | 'AnlageXorMaterial';

export const TimeEntryTypeAnlageAndMaterialOptions = {
    None: 'None' as TimeEntryTypeAnlageAndMaterialOptions,
    AnlageInBookingMandatory: 'AnlageInBookingMandatory' as TimeEntryTypeAnlageAndMaterialOptions,
    MaterialOptional: 'MaterialOptional' as TimeEntryTypeAnlageAndMaterialOptions,
    AnlageXorMaterial: 'AnlageXorMaterial' as TimeEntryTypeAnlageAndMaterialOptions
};

