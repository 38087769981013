/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PostDto } from '../model/postDto';
import { PostQuery } from '../model/postQuery';
import { PostResponse } from '../model/postResponse';
import { TagDto } from '../model/tagDto';
import { ZulipChannelDto } from '../model/zulipChannelDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NewsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favoritePost(postId: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favoritePost(postId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favoritePost(postId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favoritePost(postId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling favoritePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/news/favorite-post/${encodeURIComponent(String(postId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tagId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favoriteTag(tagId: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favoriteTag(tagId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favoriteTag(tagId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favoriteTag(tagId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling favoriteTag.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/news/favorite-tag/${encodeURIComponent(String(tagId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTags(observe?: 'body', reportProgress?: boolean): Promise<Array<TagDto>>;
    public getAllTags(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<TagDto>>>;
    public getAllTags(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<TagDto>>>;
    public getAllTags(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/allTags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredTags(observe?: 'body', reportProgress?: boolean): Promise<Array<TagDto>>;
    public getFilteredTags(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<TagDto>>>;
    public getFilteredTags(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<TagDto>>>;
    public getFilteredTags(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/filteredTagsForTenant`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrCreateChannel(id: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getOrCreateChannel(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getOrCreateChannel(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getOrCreateChannel(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrCreateChannel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/${encodeURIComponent(String(id))}/channel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostById(postId: string, observe?: 'body', reportProgress?: boolean): Promise<PostDto>;
    public getPostById(postId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PostDto>>;
    public getPostById(postId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PostDto>>;
    public getPostById(postId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getPostById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/${encodeURIComponent(String(postId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostFavorites(observe?: 'body', reportProgress?: boolean): Promise<Array<PostDto>>;
    public getPostFavorites(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<PostDto>>>;
    public getPostFavorites(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<PostDto>>>;
    public getPostFavorites(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/post-favorites`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPosts(body?: PostQuery, observe?: 'body', reportProgress?: boolean): Promise<PostResponse>;
    public getPosts(body?: PostQuery, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PostResponse>>;
    public getPosts(body?: PostQuery, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PostResponse>>;
    public getPosts(body?: PostQuery, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/news/paged`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscribedPosts(body?: PostQuery, observe?: 'body', reportProgress?: boolean): Promise<PostResponse>;
    public getSubscribedPosts(body?: PostQuery, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<PostResponse>>;
    public getSubscribedPosts(body?: PostQuery, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<PostResponse>>;
    public getSubscribedPosts(body?: PostQuery, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/news/subscribed/paged`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTagFavorites(observe?: 'body', reportProgress?: boolean): Promise<Array<TagDto>>;
    public getTagFavorites(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<TagDto>>>;
    public getTagFavorites(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<TagDto>>>;
    public getTagFavorites(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/news/tag-favorites`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavoritePost(postId: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavoritePost(postId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavoritePost(postId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavoritePost(postId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling unfavoritePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/news/unfavorite-post/${encodeURIComponent(String(postId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param tagId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavoriteTag(tagId: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavoriteTag(tagId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavoriteTag(tagId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavoriteTag(tagId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling unfavoriteTag.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/news/unfavorite-tag/${encodeURIComponent(String(tagId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
