


export type FormSignatureValidationType = 'Intern' | 'Extern';

export const FormSignatureValidationType = {
    Intern: 'Intern' as FormSignatureValidationType,
    Extern: 'Extern' as FormSignatureValidationType
};

