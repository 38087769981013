/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FormEmailDto } from '../model/formEmailDto';
import { FormInstanceCanvasImagesDto } from '../model/formInstanceCanvasImagesDto';
import { FormInstanceDto } from '../model/formInstanceDto';
import { FormInstanceHeaderDto } from '../model/formInstanceHeaderDto';
import { FormInstanceSearchDto } from '../model/formInstanceSearchDto';
import { FormInstanceSearchDtoSearchResponse } from '../model/formInstanceSearchDtoSearchResponse';
import { FormInstanceSearchRequestDto } from '../model/formInstanceSearchRequestDto';
import { SendFormEmailRequestDto } from '../model/sendFormEmailRequestDto';
import { UploadPolicy } from '../model/uploadPolicy';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FormInstanceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archive(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public archive(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public archive(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public archive(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling archive.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/form-instance/${encodeURIComponent(String(id))}/archive`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPdfRequest(id: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public downloadPdfRequest(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public downloadPdfRequest(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public downloadPdfRequest(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadPdfRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(id))}/download/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param workspaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateFormInstance(id: string, workspaceId?: string, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceSearchDto>;
    public duplicateFormInstance(id: string, workspaceId?: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceSearchDto>>;
    public duplicateFormInstance(id: string, workspaceId?: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceSearchDto>>;
    public duplicateFormInstance(id: string, workspaceId?: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling duplicateFormInstance.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workspaceId !== undefined && workspaceId !== null) {
            queryParameters = queryParameters.set('workspaceId', <any>workspaceId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/form-instance/duplicate/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public geInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public geInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public geInstanceBackgroundImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling geInstanceBackgroundImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling geInstanceBackgroundImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/instanceBackgroundImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(id: string, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceDto>;
    public get(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceDto>>;
    public get(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceDto>>;
    public get(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCombinedImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getCombinedImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getCombinedImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getCombinedImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getCombinedImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getCombinedImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/combinedImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getDownloadRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getDownloadRequest.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling getDownloadRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(filename))}/download/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDrawingImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getDrawingImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getDrawingImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getDrawingImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getDrawingImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getDrawingImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/drawingImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param workspaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFormInstanceByWorkspace(workspaceId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<FormInstanceHeaderDto>>;
    public getFormInstanceByWorkspace(workspaceId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<FormInstanceHeaderDto>>>;
    public getFormInstanceByWorkspace(workspaceId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<FormInstanceHeaderDto>>>;
    public getFormInstanceByWorkspace(workspaceId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling getFormInstanceByWorkspace.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/form-instances-by-workspace/${encodeURIComponent(String(workspaceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImages(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceCanvasImagesDto>;
    public getImages(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceCanvasImagesDto>>;
    public getImages(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceCanvasImagesDto>>;
    public getImages(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getImages.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getImages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/canvasImages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadCombinedImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadCombinedImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadCombinedImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadCombinedImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadCombinedImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadCombinedImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/uploadCombinedImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadDrawingImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadDrawingImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadDrawingImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadDrawingImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadDrawingImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadDrawingImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/uploadDrawingImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param fileName 
     * @param filetype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadFileRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadFileRequest.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling getUploadFileRequest.');
        }
        if (filetype === null || filetype === undefined) {
            throw new Error('Required parameter filetype was null or undefined when calling getUploadFileRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(filetype))}/upload/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadInstanceBackgroundImageRequest(formId: string, fieldId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadInstanceBackgroundImageRequest(formId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadInstanceBackgroundImageRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadInstanceBackgroundImageRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/form-instance/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/uploadInstanceBackgroundImage/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchForms(body?: FormInstanceSearchRequestDto, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceSearchDtoSearchResponse>;
    public searchForms(body?: FormInstanceSearchRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceSearchDtoSearchResponse>>;
    public searchForms(body?: FormInstanceSearchRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceSearchDtoSearchResponse>>;
    public searchForms(body?: FormInstanceSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/form-instance/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'body', reportProgress?: boolean): Promise<FormEmailDto>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormEmailDto>>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormEmailDto>>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/form-instance/sendEmail`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unArchive(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unArchive(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unArchive(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unArchive(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unArchive.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/form-instance/${encodeURIComponent(String(id))}/unArchive`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
