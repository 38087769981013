


export type BookingType = 'Both' | 'FromTo' | 'Quantity' | 'AdditionalDataRequired';

export const BookingType = {
    Both: 'Both' as BookingType,
    FromTo: 'FromTo' as BookingType,
    Quantity: 'Quantity' as BookingType,
    AdditionalDataRequired: 'AdditionalDataRequired' as BookingType
};

