


export type TimeEntryTypeTextOptions = 'Default' | 'InternalTextMandatory' | 'ExternalTextMandatory';

export const TimeEntryTypeTextOptions = {
    Default: 'Default' as TimeEntryTypeTextOptions,
    InternalTextMandatory: 'InternalTextMandatory' as TimeEntryTypeTextOptions,
    ExternalTextMandatory: 'ExternalTextMandatory' as TimeEntryTypeTextOptions
};

