


export type ProductUnit = 'Pieces' | 'Kilogram' | 'Ton' | 'Hours' | 'Minutes' | 'Liter' | 'Deciliter' | 'Milliliter' | 'PowerUnit' | 'Global' | 'PiecesShort' | 'Hour' | 'Flat' | 'Kilometer' | 'Meter' | 'Centimeter' | 'Millimeter' | 'SquareMeter' | 'CubicMeter' | 'LinearMeter' | 'User' | 'Year' | 'Month' | 'Day' | 'Week' | 'Bag' | 'Roll' | 'Pack' | 'Pair' | 'Fitting' | 'Set' | 'Boiler' | 'Canister' | 'FluidOunce' | 'Percent' | 'Sack10' | 'Sack15' | 'Sack20' | 'Sack25' | 'Sack30' | 'Sack35' | 'Sack40' | 'Volume' | 'SquareVolume' | 'CubicVolume' | 'Per10' | 'Per100' | 'Per1000';

export const ProductUnit = {
    Pieces: 'Pieces' as ProductUnit,
    Kilogram: 'Kilogram' as ProductUnit,
    Ton: 'Ton' as ProductUnit,
    Hours: 'Hours' as ProductUnit,
    Minutes: 'Minutes' as ProductUnit,
    Liter: 'Liter' as ProductUnit,
    Deciliter: 'Deciliter' as ProductUnit,
    Milliliter: 'Milliliter' as ProductUnit,
    PowerUnit: 'PowerUnit' as ProductUnit,
    Global: 'Global' as ProductUnit,
    PiecesShort: 'PiecesShort' as ProductUnit,
    Hour: 'Hour' as ProductUnit,
    Flat: 'Flat' as ProductUnit,
    Kilometer: 'Kilometer' as ProductUnit,
    Meter: 'Meter' as ProductUnit,
    Centimeter: 'Centimeter' as ProductUnit,
    Millimeter: 'Millimeter' as ProductUnit,
    SquareMeter: 'SquareMeter' as ProductUnit,
    CubicMeter: 'CubicMeter' as ProductUnit,
    LinearMeter: 'LinearMeter' as ProductUnit,
    User: 'User' as ProductUnit,
    Year: 'Year' as ProductUnit,
    Month: 'Month' as ProductUnit,
    Day: 'Day' as ProductUnit,
    Week: 'Week' as ProductUnit,
    Bag: 'Bag' as ProductUnit,
    Roll: 'Roll' as ProductUnit,
    Pack: 'Pack' as ProductUnit,
    Pair: 'Pair' as ProductUnit,
    Fitting: 'Fitting' as ProductUnit,
    Set: 'Set' as ProductUnit,
    Boiler: 'Boiler' as ProductUnit,
    Canister: 'Canister' as ProductUnit,
    FluidOunce: 'FluidOunce' as ProductUnit,
    Percent: 'Percent' as ProductUnit,
    Sack10: 'Sack10' as ProductUnit,
    Sack15: 'Sack15' as ProductUnit,
    Sack20: 'Sack20' as ProductUnit,
    Sack25: 'Sack25' as ProductUnit,
    Sack30: 'Sack30' as ProductUnit,
    Sack35: 'Sack35' as ProductUnit,
    Sack40: 'Sack40' as ProductUnit,
    Volume: 'Volume' as ProductUnit,
    SquareVolume: 'SquareVolume' as ProductUnit,
    CubicVolume: 'CubicVolume' as ProductUnit,
    Per10: 'Per10' as ProductUnit,
    Per100: 'Per100' as ProductUnit,
    Per1000: 'Per1000' as ProductUnit
};

