


export type CostCentreOptions = 'Fixed' | 'FromEmployee' | 'FixedTo' | 'Unknown';

export const CostCentreOptions = {
    Fixed: 'Fixed' as CostCentreOptions,
    FromEmployee: 'FromEmployee' as CostCentreOptions,
    FixedTo: 'FixedTo' as CostCentreOptions,
    Unknown: 'Unknown' as CostCentreOptions
};

