


export type ServiceContractType = 'None' | 'ServiceOrder' | 'UpkeepOrder' | 'ApprovalInquiry' | 'MaintenanceContract';

export const ServiceContractType = {
    None: 'None' as ServiceContractType,
    ServiceOrder: 'ServiceOrder' as ServiceContractType,
    UpkeepOrder: 'UpkeepOrder' as ServiceContractType,
    ApprovalInquiry: 'ApprovalInquiry' as ServiceContractType,
    MaintenanceContract: 'MaintenanceContract' as ServiceContractType
};

