


export type CustomerReportEmailType = 'External' | 'Internal';

export const CustomerReportEmailType = {
    External: 'External' as CustomerReportEmailType,
    Internal: 'Internal' as CustomerReportEmailType
};

