


export type TodoType = 'All' | 'AccessLegitimation' | 'Form';

export const TodoType = {
    All: 'All' as TodoType,
    AccessLegitimation: 'AccessLegitimation' as TodoType,
    Form: 'Form' as TodoType
};

