


export type TimeEntryTypeUnitType = 'Hourly' | 'Daily' | 'Default';

export const TimeEntryTypeUnitType = {
    Hourly: 'Hourly' as TimeEntryTypeUnitType,
    Daily: 'Daily' as TimeEntryTypeUnitType,
    Default: 'Default' as TimeEntryTypeUnitType
};

