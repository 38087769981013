


export type EmployeePresenceStatus = 'NotSet' | 'Active' | 'Busy' | 'Absent';

export const EmployeePresenceStatus = {
    NotSet: 'NotSet' as EmployeePresenceStatus,
    Active: 'Active' as EmployeePresenceStatus,
    Busy: 'Busy' as EmployeePresenceStatus,
    Absent: 'Absent' as EmployeePresenceStatus
};

